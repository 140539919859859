	// Add your JS customizations here
jQuery(document).ready(function ($) {
 
    $(".news-owl-silder").owlCarousel({
      nav: true,
      center: true,
      rtl: true,
      items: 1,
      loop: true,
      lazyLoad: true,
      margin: 20,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          margin: 20,
          items: 2,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 2,
        },
        2000: {
          items: 4,
        },
      },
    });
  
    $(".state-owl-silder").owlCarousel({
      nav: true,
      rtl: true,
      center: true,
      items: 1,
      loop: true,
      lazyLoad: true,
      margin: 20,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          margin: 10,
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
        2000: {
          items: 7,
        },
      },
    });
   
  
    if (document.location.href.indexOf("#success") > -1) {
      $(function () {
        if (document.documentElement.lang.toLowerCase() === "ar") {
          alert("تم استقبال رسالتكم و سيتم التواصل معكم في اقرب وقت");
        } else {
          alert(
            "Your message has been received and we will communicate with you as soon as possible"
          );
        }
      });
    }
  
    var interleaveOffset = 0.5;
  
    var swiperOptions = {
      loop: true,
      lazy: true,
      speed: 1000,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      grabCursor: true,
      watchSlidesProgress: true,
      mousewheelControl: true,
      keyboardControl: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        progress: function () {
          var swiper = this;
          for (var i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform =
              "translate3d(" + innerTranslate + "px, 0, 0)";
          }
        },
        touchStart: function () {
          var swiper = this;
          for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
          }
        },
        setTransition: function (speed) {
          var swiper = this;
          for (var i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + "ms";
            swiper.slides[i].querySelector(".slide-inner").style.transition =
              speed + "ms";
          }
        },
      },
    };
  
    var swiper = new Swiper(".swiper-container-img", swiperOptions);
  
    $(".swiper.swiper-container-img").on("mouseover", function () {
      swiper.autoplay.stop();
    });
  
    $(".swiper.swiper-container-img").on("mouseout", function () {
      swiper.autoplay.start();
    });
  
    var swiper_news = new Swiper(".swiper-container-slider-news", {
      direction: "vertical",
      slidesPerView: "auto",
      freeMode: true,
      loop: true,
      lazy: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      mousewheel: true,
    });
  
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  
    var swiper2 = new Swiper(".swiper-container-slider-details", {
      direction: "vertical",
      slidesPerView: 1,
      loop: true,
      lazy: true,
      spaceBetween: 30,
      mousewheel: true,
      keyboard: {
        enabled: true,
      },
      // autoplay: {
      //     delay: 2500,
      //     disableOnInteraction: false,
      // },
      pagination: {
        el: ".swiper-pagination-slider-details",
        clickable: true,
      },
      breakpoints: {
        // // when window width is <= 320px
        // 320: {
        //   slidesPerView: 1,
        //   spaceBetween: 10
        // },
        // // when window width is <= 480px
        // 480: {
        //   slidesPerView: 2,
        //   spaceBetween: 20
        // },
        // // when window width is <= 640px
        992: {
          direction: "horizontal",
          mousewheel: false,
          spaceBetween: 100,
        },
      },
    });
    $(".details-slide").on("click", function (e) {
      e.preventDefault();
      swiper2.slideTo(0, 0);
    });
    $(".description-slide").on("click", function (e) {
      e.preventDefault();
      swiper2.slideTo(1, 0);
    });
    $(".info-location-slide").on("click", function (e) {
      e.preventDefault();
      swiper2.slideTo(2, 0);
    });
    $(".location-slide").on("click", function (e) {
      e.preventDefault();
      swiper2.slideTo(3, 0);
    });
  
    $(".fa-angle-left").hover(
      function () {
        $(".nav-previous").css({ "z-index": 3 });
        $(".nav-next").css({ "z-index": 0 });
      },
      function () {
        $(".nav-previous").css({ "z-index": 3 });
        $(".nav-next").css({ "z-index": 0 });
      }
    );
    $(".fa-angle-right").hover(
      function () {
        $(".nav-previous").css({ "z-index": 0 });
        $(".nav-next").css({ "z-index": 3 });
      },
      function () {
        $(".nav-previous").css({ "z-index": 0 });
        $(".nav-next").css({ "z-index": 3 });
      }
    );
  
    $("ul.dropdown-menu").addClass("animate slideIn");
  
    $("input[type=tel]").each(function () {
      var input1 = this;
      window.intlTelInput(input1, {
        hiddenInput: "full_phone",
        autoPlaceholder: "off",
        initialCountry: "auto",
        autoHideDialCode: false,
        preferredCountries: [
          "tr",
          "dz",
          "sa",
          "eg",
          "iq",
          "ir",
          "jd",
          "lb",
          "ly",
          "ma",
          "ps",
          "kw",
          "jd",
          "om",
          "qa",
          "ye",
          "ae",
          "tn",
          "sy",
          "bh",
        ],
        geoIpLookup: function (success, failure) {
          $.get(
            "https://ipinfo.io/?token=d1859b9f267f1e",
            function () {},
            "jsonp"
          ).always(function (resp) {
            var countryCode = resp && resp.country ? resp.country : "TR";
            success(countryCode);
          });
        },
        separateDialCode: true,
        utilsScript: "https://realtur.com.tr/wp-content/themes/understrap-main/js/libraries/utils.js",
      });
    });
  
    //for close and open the filter
    $("#close").click(function () {
      $(".filter-warp").removeClass("show-left");
      $("#close").addClass("hide-button");
      $("#filter").removeClass("hide-button");
    });
    $("#filter").click(function () {
      $(".filter-warp").addClass("show-left");
      $("#filter").addClass("hide-button");
      $("#close").removeClass("hide-button");
      $("#close").addClass("show-button");
    });
  
    //for close and open the contact form single page
    $("#close-contact").click(function () {
      $(".contact-us-fixed").addClass("left");
      $("#close-contact").addClass("hide-button");
      $("#open-contact").removeClass("hide-button");
      $("#open-contact").addClass("show-button");
    });
  
    $("#open-contact").click(function () {
      $(".contact-us-fixed").removeClass("left");
      $("#open-contact").addClass("hide-button");
      $("#close-contact").removeClass("hide-button");
      $("#close-contact").addClass("show-button");
    });
  
    /////////////////land single page swiper///////////////////
    var swiper4 = new Swiper(".swiper-singl-land", {
      navigation: {
        nextEl: ".swiper-gallery-next",
        prevEl: ".swiper-gallery-prev",
      },
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
    });
  
    $(".swiper-singl-land").on("mouseover", function () {
      swiper4.autoplay.stop();
    });
  
    $(".swiper-singl-land").on("mouseout", function () {
      swiper4.autoplay.start();
    });
  
    /////////////////////////////////////////////////////
    // Exit intent
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      if (!$.cookie("exit-intent-closed")) {
        setTimeout(function () {
          $("#popup-contact-form").modal("show");
          $.cookie("exit-intent-closed", true, { expires: 30, path: "/" });
        }, 20000);
      } else {
      }
    } else {
      function addEvent(obj, evt, fn) {
        if (obj.addEventListener) {
          obj.addEventListener(evt, fn, false);
        } else if (obj.attachEvent) {
          obj.attachEvent("on" + evt, fn);
        }
      }
  
      // check for cookie
      if (!$.cookie("exit-intent-closed")) {
        document.addEventListener(
          "mouseleave",
          function (e) {
            if (e.clientY < 0) {
              $("#popup-contact-form").modal("show");
              $.cookie("exit-intent-closed", true, { expires: 30, path: "/" });
            }
          },
          false
        );
      } else {
        // if no cookie, check for user leaving
      }
    }
  
    $("form.validation").each(function () {
      $(this).validate({
        errorPlacement: function (error, element) {
          error.insertAfter(element.closest("div"));
        },
      });
    });
  
    var galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 10,
      slidesPerView: 7,
      loop: true,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    var galleryTop = new Swiper(".gallery-top", {
      spaceBetween: 10,
      loop: true,
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: ".swiper-gallery-next",
        prevEl: ".swiper-gallery-prev",
      },
      thumbs: {
        swiper: galleryThumbs,
      },

    });
  
    var swiper = new Swiper(".story-slider", {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      rtl: true,
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      breakpoints: {
        540: {
          spaceBetween: 0,
        },
        768: {
          spaceBetween: 5,
        },
        992: {
          spaceBetween: 10,
        },
        1200: {
          spaceBetween: 10,
        },
      },
    });
  
    //this slider keyword of home page in index.php
    var swiperKeyWord = new Swiper(".keyWord-slider", {
      slidesPerView: "auto",
      autoplay: {
        delay: 3000,
      },
      loop: true,
      rtl: true,
      spaceBetween: 10,
      setWrapperSize: true,
      navigation: {
        nextEl: ".button-next-keyword",
        prevEl: ".button-prev-keyword",
      },
      breakpoints: {
        540: {
          slidesPerView: 3,
          freeMode: true,
        },
        768: {
          freeMode: true,
          slidesPerView: 6,
        },
        992: {
          freeMode: false,
        },
        1200: {},
      },
    });
  
    //this all slider of home page in index.php
    var swiper = new Swiper(".swiper.special-offers-owl-silder", {
      slidesPerView: 1,
      spaceBetween: 16,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      rtl: true,
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      breakpoints: {
        540: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        992: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
      },
    });
    
    //this all slider of home page in index.php
    var swiper = new Swiper(".swiper.new-special-offers-owl-silder", {
      slidesPerView: 1,
      spaceBetween: 16,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      rtl: true,
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      breakpoints: {
        540: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        992: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 15,
          slidesPerView: 5,
        },
      },
    });
  
    //this all slider of home page in index.php
    var swiper = new Swiper(".swiper.youtube-silder", {
      slidesPerView: 1,
      spaceBetween: 12,
      autoplay: {
        delay: 6000,
      },
      loop: true,
      rtl: true,
      navigation: {
        nextEl: ".button-next-y",
        prevEl: ".button-prev-y",
      },
      breakpoints: {
        540: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 1,
        },
        992: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1200: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });
  
        // Gets the video src from the data-src on each button

        var $videoSrc;
        $('.video-btn').click(function() {
            $videoSrc = $(this).data("src");
        });
        console.log($videoSrc);



        // when the modal is opened autoplay it  
        $('#youtubeModal').on('shown.bs.modal', function(e) {

            // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
            $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");

        })



        // stop playing the youtube video when I close the modal
        $('#youtubeModal').on('hide.bs.modal', function(e) {
            // a poor man's stop video
            $("#video").attr('src', $videoSrc);
        })

  
   

    // "use strict";

    // /*==================================================================
    //     [ Validate after type ]*/
    // $(".validate-input .input100").each(function () {
    //   $(this).on("blur", function () {
    //     if (validate(this) == false) {
    //       showValidate(this);
    //     } else {
    //       $(this).parent().addClass("true-validate");
    //     }
    //   });
    // });

    // /*==================================================================
    //     [ Validate ]*/
    // var input = $(".validate-input .input100");

    // $(".validate-form").on("submit", function () {
    //   var check = true;

    //   for (var i = 0; i < input.length; i++) {
    //     if (validate(input[i]) == false) {
    //       showValidate(input[i]);
    //       check = false;
    //     }
    //   }

    //   return check;
    // });

    // $(".validate-form .input100").each(function () {
    //   $(this).focus(function () {
    //     hideValidate(this);
    //     $(this).parent().removeClass("true-validate");
    //   });
    // });

    // function validate(input) {
    //   if (
    //     $(input).attr("type") == "email" ||
    //     $(input).attr("name") == "email"
    //   ) {
    //     if (
    //       $(input)
    //         .val()
    //         .trim()
    //         .match(
    //           /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
    //         ) == null
    //     ) {
    //       return false;
    //     }
    //   } else {
    //     if ($(input).val().trim() == "") {
    //       return false;
    //     }
    //   }
    // }

    // function showValidate(input) {
    //   var thisAlert = $(input).parent();

    //   $(thisAlert).addClass("alert-validate");

    //   $(thisAlert).append(
    //     '<i class="fa fa-times btn-hide-validate" aria-hidden="true"></i>'
    //   );
    //   $(".btn-hide-validate").each(function () {
    //     $(this).on("click", function () {
    //       hideValidate(this);
    //     });
    //   });
    // }

    // function hideValidate(input) {
    //   var thisAlert = $(input).parent();
    //   $(thisAlert).removeClass("alert-validate");
    //   $(thisAlert).find(".btn-hide-validate").remove();
    // }
  
    // this function for disabil the back button on press on it to close the bootstrap model
    // jQuery(document).ready(function($) {
  
    // 	if (window.history && window.history.pushState) {
  
    // 		window.history.pushState('forward', null, './#forward');
  
    // 		$(window).on('popstate', function() {
    // 			alert('Back button was pressed.');
    // 			$('#popup-contact-form').modal('hide');
    // 		});
  
    // 	}
    // });
  
    // loader
    // $(window).on('load', function () {
    //     $('#status').fadeOut();
    //     $('#preloader').delay(50).fadeOut('slow');
    //     $('body').delay(250).css({
    //         'overflow': 'visible'
    //     });
    // });

    $(".whatssapp_photo .elementor-image a").addClass("whatssapp_photo");
    $(".whatssapp_icon .elementor-image a").addClass("whatssapp_icon");
  }); //jQuery(document)
 
